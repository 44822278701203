@import '../../../../styles/inc/fonts'
@import '../../../../styles/inc/consts'

.layout
  position: relative

  @media ( min-width: $mobile )
    display: none

  &__header
    &::before
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 18rem
      background: var(--radial2)
      content: ''
      z-index: -1

    &-items
      display: flex
      justify-content: space-between
      align-items: center

      padding: 1.2rem 2rem 1rem 2rem

    &-title
      @include F17Medium
      color: var(--white100)
      text-align: center

  &__footer
    padding: 2.2rem 3rem 2.3rem 3rem
    display: flex
    align-items: center
    background: var(--radial2)
    position: sticky
    position: -webkit-sticky
    position: -moz-sticky
    position: -o-sticky
    bottom: 0
    z-index: 10
    height: 7rem
    // &::before
    //   position: absolute
    //   bottom: 0
    //   left: 0
    //   width: 100%
    //   height: 15rem
    //   background: var(--radial2)
    //   content: ''
    //   z-index: -1

    &-button
      position: relative
      &-revers
        position: relative
        top: -2px
        transform: rotate(180deg)
    &-price
      @include F20Bold
      color: var(--white100)
      margin-left: .7rem
      min-width: 40%

    &-btn
      width: 100%
      button
        width: 100%