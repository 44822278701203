@import '../../../styles/inc/fonts'
  
.nav
  background: var(--stepsBg)
  border-radius: .8rem
  margin: 1.1rem 2rem


  &__items
    list-style: none
    display: grid
    grid-template-columns: 1fr 1fr 1fr

  &-item
    height: 4rem
    display: flex
    justify-content: center
    align-items: center
    @include F13Bold
    color: var(--white60)
    
    img
      margin-right: .5rem

    &--active
      background: var(--white100)
      border-radius: .8rem
      box-shadow: 0 .1rem .3rem rgba(20, 10, 5, 0.25), 0 .4rem 1.6rem rgba(20, 10, 5, 0.25)
      color: var(--text900)
    &--white
      color: var(--white100)