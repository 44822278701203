@import '../../styles/inc/fonts'
  
.agent
  display: flex
  align-items: center
  justify-content: flex-end

  &__info
    text-align: end

    &-name
      @include F13Book
      color: var(--text100)
      display: block
    &-phone
      @include F13Book
      color: var(--text100)
      display: flex
      align-items: center
      svg
        margin-right: .48rem

  &__avatar
    position: relative
    margin-left: 1.2rem

    &-status
      width: 1.2rem
      height: 1.2rem
      border-radius: 100%
      background-color: var(--green100)
      box-shadow: 0 0 0 .35rem var(--bgHome)
      position: absolute
      top: 0
      left: 0
    &-img
      width: 4.8rem
      height: 4.8rem
      object-fit: cover
      border-radius: 3.2rem