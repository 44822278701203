@import '../../../../styles/inc/fonts'
@import '../../../../styles/inc/consts'
@import '../../../../styles/inc/mixins'

.layout
  position: relative
  display: none

  @media ( min-width: $mobile )
    display: block

  .header
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    padding: 2rem 2rem 1.5rem 2rem
    background: var(--radial2)

    &__title
      display: flex
      justify-content: center
      align-items: center
      @include F17Medium
      color: var(--white100)

    &__agent
      height: fit-content

  .main
    background: var(--text50)
    border-radius: 2rem 2rem 0 0
    margin-top: -1.5rem
    height: calc(100vh - 106px)
    overflow-y: scroll
    @include hideScroll