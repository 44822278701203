@import '../../styles/inc/fonts'
@import '../../styles/inc/effects'
@import '../../styles/inc/consts'

.breakdown
  margin: 1rem
  border-radius: 1.2rem
  
  &-info
    padding: 0 2.5rem

  &-title
    @include F20Medium
    color: var(--text900)
    margin-bottom: 2rem
    padding: 2.5rem 2.5rem 0 2.5rem
    font-family: "CircularStd_Bold"
    text-transform: capitalize

  .line
    display: flex
    align-items: center
    justify-content: space-between

    &--spacer
      margin-bottom: 1.5rem

    &-title
      @include F13Book
      color: var(--text400)
      &--bold
        @include F15Medium
        color: var(--text900)
        margin-bottom: 1.5rem

    &-value
      @include F13Book
      color: var(--text400)
      &--bold
        @include F15Medium
        color: var(--text900)
        margin-bottom: 1.5rem

    &-button
      width: fit-content
      padding: .3rem .5rem
      border-radius: .6rem
      background: var(--action50)
      @include F12Medium
      color: var(--action500)
      display: flex
      align-items: center
      justify-content: center
      svg
        margin-right: .5rem

  &-footer
    display: flex
    align-items: center
    justify-content: space-between
    padding: 2rem 2.5rem 2.5rem 2.5rem

    .title
      @include F20Bold
      color: var(--text900)
    .value
      @include F24Medium
      color: var(--text900)